export const optionsMoney = [
  { id: '24999', value: '$5K - $25K' },
  { id: '49999', value: '$25K - $50K' },
  { id: '99999', value: '$50K - $100K' },
  { id: '249999', value: '$100K - $250K' },
  { id: '499999', value: '$250K - $500K' },
  { id: '999999', value: '$500K - $1M' },
  { id: '1000000', value: 'Over $1M' },
];

export const optionsCreditScore = [
  { id: '499', value: '(499 or Below) Poor' },
  { id: '599', value: '(500 - 599) Not so Good' },
  { id: '649', value: '(600 - 649) Okay' },
  { id: '679', value: '(650 - 679) Good' },
  { id: '719', value: '(680 - 719) Great' },
];

export const optionsTimeInBusiness = [
  { id: '2', value: '0-2 months' },
  { id: '5', value: '3-5 months' },
  { id: '11', value: '6-11 months' },
  { id: '24', value: '1-2 years' },
  { id: '36', value: '2-3 years' },
  { id: '60', value: '3-5 years' },
  { id: '61', value: '5 or more years' },
];

export const optionsMonthlySales = [
  { id: '3999', value: '$1 - $4K' },
  { id: '7999', value: '$5K - $7K' },
  { id: '14999', value: '$8K - $14K' },
  { id: '19999', value: '$15K - $19K' },
  { id: '49999', value: '$20K - $49K' },
  { id: '79999', value: '$50K - $79K ' },
  { id: '199999', value: '$80K - $199K' },
  { id: '399999', value: '$200K - $399K' },
  { id: '400000', value: 'More than $400K' },
];
