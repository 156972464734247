
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseRadioButton from '@/components/baseComponents/BaseRadioButton.vue';
import { IOptions } from '@/models';

@Component({
  components: { BaseRadioButton },
})
export default class BaseRadioButtonGroup extends Vue {
  @Prop({ default: false }) error!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: '' }) name!: string;
  @Prop({ default: '' }) radioGroupTitle!: string;
  @Prop({ default: null }) options!: IOptions[];
  @Prop({ default: '' }) value!: any;

  get selectedOption(): string {
    return this.value;
  }

  set selectedOption(value: string) {
    this.$emit('input', value);
  }
}
