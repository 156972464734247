
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IOptions } from '../../models';

@Component
export default class BaseRadioButton extends Vue {
  @Prop({ default: false }) checked!: boolean;
  @Prop({ default: false }) error!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: null }) index!: number;
  @Prop({ default: null }) option!: IOptions;
  @Prop({ default: '' }) value!: string;
  @Prop({ default: '' }) name!: string;

  get isDisabledIcon(): string {
    return this.disabled ? '_disabled' : '';
  }

  updateValue(): void {
    this.$emit('input', this.option.id);
  }
}
